.users-container-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 1rem 0 1rem;
}

.users-title {
  font-size: 1.8rem;
}

.users-path {
  color: #6c757d;
}

.users-path a {
  padding-right: 8px;
}

.users-path span {
  padding-left: 8px;
}

.users-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 12px 16px 16px 16px;
  gap: 21px;
}

.user-container {
  background: white;
  padding: 12px 21px;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}

.user-top {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.user-top::after {
  content: '';
  position: absolute;
  bottom: -13.2px;
  width: 100%;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  border-bottom: 1px solid rgba(128, 128, 128, 0.2);
}

.user-top.active::after {
  opacity: 1;
}

.user-top .fas {
  transform: rotate(90deg);
  transition: transform 0.2s ease-in-out;
}

.user-top.active .fas {
  transform: rotate(0deg);
}

.user-left {
  display: flex;
  gap: 21px;
  align-items: center;
}

.user-image img {
  width: 55px;
  height: 55px;
  border-radius: 50%;
}

.user-name {
  font-size: 1.1rem;
}

.user-permissions-section {
  max-height: 0px;
  transition: max-height 0.28s ease-in-out, margin-top 0.28s ease-in-out;
  z-index: 1;
  overflow: hidden;
}

.user-permissions-section.active {
  max-height: 520px;
  overflow: auto;
  margin-top: 1rem;
}

.user-permissions-container {
  padding: 1.8rem 1.2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 35px;
}

.permissions-heading {
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
  border-bottom: 1px solid rgba(128, 128, 128, 0.2);
  padding-bottom: 4px;
}

.permissions-container {
  border: 1px solid rgba(128, 128, 128, 0.3);
  padding: 8px 12px;
  min-width: 265px;
}

.permissions-list {
  padding: 0 1rem;
  margin: 16px 0;
  max-height: 120px;
  overflow: auto;
}

.permissions-list::-webkit-scrollbar {
  width: 16px;
}

.user-permissions-section::-webkit-scrollbar {
  width: 0px;
}

.permissions-list::-webkit-scrollbar-track,
.user-permissions-section::-webkit-scrollbar-track {
  background: #ffffff;
}

.permissions-list::-webkit-scrollbar-thumb,
.user-permissions-section::-webkit-scrollbar-thumb {
  background-color: rgba(33, 37, 41, 0.3);
  border-radius: 0px;
  border: 3px solid #ffffff;
}

.permissions-item input {
  cursor: pointer;
}

.permissions-item label {
  font-weight: 400 !important;
  font-size: 1rem;
  margin-left: 16px;
}
